import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

export const Wrapper = styled.div`
  padding-top: 3rem;

  @media (max-width: 680px) {
    padding-top: 1rem;
  }

  h1 {
    margin-bottom: 0;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-bottom: 5rem;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 600px) {
    padding-top: 0;
  }
`;

export const Link = styled(GatsbyLink)`
  text-decoration: none !important;
  padding: 1rem 0;
  width: calc(33.33% - 1rem);

  &:hover {
    color: #000;
  }

  @media (max-width: 1280px) {
    width: calc(50% - 1rem);
  }

  @media (max-width: 870px) {
    width: 100%;
  }
`;
