import React from 'react';
import { Post } from '../Post';
import { CardContainer, Link, Wrapper } from './styles';
import { Container } from 'components/common/Container';

interface PostProps {
  posts: any[];
}

export const Posts: React.FC<PostProps> = ({ posts }) => (
  <Wrapper>
    <Container noYPadding>
      <h1>Latests Posts</h1>
    </Container>

    <Container>
      <CardContainer>
        {posts.map(({ node }: any) => (
          <Link
            key={node.fields.slug}
            style={{ boxShadow: `none` }}
            to={node.fields.slug}
          >
            {<Post excerpt={node.excerpt} {...node.frontmatter} />}
          </Link>
        ))}
      </CardContainer>
    </Container>
  </Wrapper>
);
