import React from 'react';
import { Layout, SEO } from 'components/common';
import { Header } from 'components/theme';
import { graphql } from 'gatsby';
import { Posts } from 'components/blog';

export default ({ data }: any) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <SEO title={'Paneau - Blog'} />
      <Header product="advertising" />
      <Posts posts={posts} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            minutes
            banner {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            preview {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            bannerAlt
            author
            authorImg {
              childImageSharp {
                fluid(maxWidth: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            category
          }
        }
      }
    }
  }
`;
