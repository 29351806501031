import React from 'react';
import {
  Card,
  Image,
  Label,
  ReadMoreContainer,
  ReadMore,
  Date,
} from './styles';

interface PostProps {
  preview: any;
  title: string;
  bannerAlt: string;
  date: string;
  category: string;
  excerpt: string;
}

export const Post: React.FC<PostProps> = ({
  preview,
  title,
  bannerAlt,
  date,
  category,
  excerpt,
}) => (
  <Card>
    <Image fluid={preview.childImageSharp.fluid} alt={bannerAlt} />
    <Label>{category}</Label>
    <h3>{title}</h3>
    <Date>{date}</Date>
    <p>{excerpt}</p>
    <ReadMoreContainer>
      <ReadMore>Read More →</ReadMore>
    </ReadMoreContainer>
  </Card>
);
