import styled from 'styled-components';
import { Container } from 'components/common/Container';
import { default as GatsbyImage } from 'gatsby-image';

export const Wrapper = styled(Container)`
  width: 100%;
`;

interface BannerProps {
  backgroundUri: string;
}

export const Banner = styled.div<BannerProps>`
  background: url(${props => props.backgroundUri});
`;

export const Card = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 1rem;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;

  span {
    text-decoration: none !important;
  }
`;

export const Label = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  padding: 0.5rem 0;
  color: #aaa;
`;

export const Image = styled(GatsbyImage)`
  height: 300px;
`;

export const ReadMoreContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
`;

export const ReadMore = styled.div`
  font-weight: 700;
  text-transform: uppercase;
`;

export const Date = styled.div`
  font-weight: 700;
`;
